import React from 'react'
import { Link } from 'gatsby'
import logo from '../assets/images/brewedlogic-logo-horizontal-blue.svg'
import image404 from '../assets/images/default404/404-page-not-found.png'

const default404 = () => {
  return (
    <div className="coming-soon-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="coming-soon-content">
            <Link to="/" className="logo">
              <img src={logo} alt="logo" />
            </Link>
            <h4>Oops! We can't seem to find the page you're looking for...</h4>

            <div className="flex-wrap d-flex justify-content-center">
            <img src={image404} alt="404" />
            </div>

            <Link to="/" activeClassName="active" className="default-btn">
              <i className="flaticon-left"></i> Back to homepage
              <span></span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default default404
